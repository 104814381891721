<template>
    <div class="m-2" :style="`direction:`+lang.dir">
        <b-sidebar backdrop id="view_card" width="95%" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right :title="lang.view_card" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:150px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class=" btn-sm text-center">
                        <span>{{lang.view_card}}</span>
                    </div>
                    <div @click="hide" id="closecardview" style="text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer;width:100px;" class="btn-danger btn-sm">
                        <span>{{lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <div :style="`direction:`+lang.dir">
                    <div style="display:inline-flex;width:11.111%;" class="pa-1">
                        <b-button class="mybtn2 btn-sm" style="background:#000 !important;color:#FFF" v-b-toggle.add_gallery> {{lang.photo_gallary}} </b-button>
                    </div>

                    <div style="display:inline-flex;width:11.111%" class="pa-1">
                        <b-button class="mybtn2 btn-sm" style="background:#000 !important;color:#FFF" @click="printMe()">{{lang.print_card}} </b-button>
                    </div>

                    <div class="pa-1" style="display:inline-flex;width:11.111%" v-if="card.status == -500 && card.status > 0 && card.status < 100 && showaddinv">
                        <b-button class="mybtn2 btn-sm" style="background:red !important;color:#fff" v-if="showaddinv" v-b-toggle.cancelCard>الغاء البطاقة </b-button>
                    </div>

                    <div class="pa-1" style="display:inline-flex;width:12.111%" v-if="sums.remain > 0">
                        <b-button @click="getPay" v-b-toggle.add_addpayment class="mybtn btn-sm" style="background:green"><i class="fas fa-money-bill"></i>&nbsp;&nbsp; {{lang.make_payment}} </b-button>
                    </div>

                    <div class="pa-1" style="display:inline-flex;width:11.111%" v-if="card.status > 0">
                        <b-button class="mybtn1 btn-sm" @click="doCreate()" style="background:green !important" v-if="showaddinv">{{lang.invoices}} </b-button>
                        <b-button class="mybtn3 btn-sm" v-if="!showaddinv" @click="printInvo()">{{lang.print_invoice}} </b-button>
                    </div>
                    <div class="pa-1" style="display:inline-flex;width:11.111%" v-if="card.status > 0 && card.status < 100">
                        <b-button class="mybtn3 btn-sm" style="margin-right:5px;background:green !important" @click="closeCard()">{{lang.close_card}} </b-button>
                    </div>
                    <!-- <div class="pa-1" style="display:inline-flex;width:11.111%" v-if="card.status > 0 && card.status < 100">
                        <b-button style="background:red !important;font-size:1.2em;margin-right:5px;" class="mybtn4" @click="saveCard()">{{lang.save}} </b-button>
                    </div> -->
                </div>
                <div style="margin:5px;" class="rowTitle">
                    <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" :style="`direction:`+lang.dir" class="p-1" role="tab">
                        <div style="display:inline-flex;width:11.111%">
                        {{lang.cardid}}: {{ cardid }}
                        </div>
                        
                    
                        <div style="display:inline-flex;width:22.222%">
                        {{lang.added_date}}: &nbsp;&nbsp; <span style="direction:ltr">{{ String(carddate).substr(0, 10) }}</span>
                        </div>
                        <div style="display:inline-flex;width:22.222%">
                        {{lang.added_time}}: &nbsp;&nbsp; <span style="direction:ltr">{{ String(cardtime).substr(11, 10) }}</span>
                        </div>
                    </b-card-header>

                    </b-card>
                </div> 
                <v-row :style="`direction:`+lang.dir" class="m-2">
                    <v-col cols="12" md="6" sm="12">
                        <v-card
                            elevation="1"
                            outlined
                            shaped
                            tile
                            style="height:149px;"
                        >
                            <v-card-title>
                            <b-button-group style="width:100% !important;background:#000;color:#FFF">
                                <b-button variant="light" style="font-weight:bold;background:#000;color:#FFF;border-radius:0;border:1px solid #000;">{{lang.customer_info}}  </b-button>
                                <b-button variant="light" style="font-weight:bold;background:#000;color:#FFF;border-radius:0;border:1px solid #000;"></b-button>
                                <b-button variant="light" style="font-weight:bold;background:#000;color:#FFF;border-radius:0;border:1px solid #000;"></b-button>
                                <b-button variant="light" style="width:25px;display:none;background:#000;color:#FFF;border-radius:0;border:1px solid #000;"><i style="color:#111" class="fas fa-pencil-alt"></i></b-button>
                            </b-button-group>
                            </v-card-title>
                            <v-card-text>
                                <v-simple-table striped hover style="width:100%;">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th style="border:1px solid #ccc;">{{lang.customer_name}}</th>
                                                <td>{{ Customer.name }}</td>
                                                <th style="border:1px solid #ccc;">{{lang.mobile}}</th>
                                                <td>{{ Customer.mobile }}</td>
                                            </tr>
                                            <tr v-if="company.name != ''">
                                                <th style="border:1px solid #ccc;">{{lang.company_name}}</th>
                                                <td>{{ company.name }}</td>
                                                <th style="border:1px solid #ccc;">{{lang.company_vatid}}</th>
                                                <td>{{ company.vatid }}</td>
                                            </tr>
                                        </thead>
                                    </template>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-card
                            elevation="1"
                            outlined
                            shaped
                            tile
                        >
                            <v-row>
                                <v-col cols="12" style="padding-top:0px;">
                                    <v-card-title>
                                        <b-button-group style="width:100% !important;color:#FFF;border-radius:0;border:1px solid #000background:#000;color:#FFF;;">
                                            <b-button variant="light" style="font-weight:bold;background:#000;color:#FFF;border-radius:0;border:1px solid #000;">{{lang.car_info}}  </b-button>
                                            <b-button variant="light" style="color:#FFF;border-radius:0;border:1px solid #000;background:#000;color:#FFF;"></b-button>
                                            <b-button variant="light" style="color:#FFF;border-radius:0;border:1px solid #000;background:#000;color:#FFF;"></b-button>
                                            <b-button variant="light" style="width:25px;display:none;color:#FFF;border-radius:0;border:1px solid #000;background:#000;color:#FFF;"><i style="color:#111" class="fas fa-pencil-alt"></i></b-button>
                                        </b-button-group>
                                    </v-card-title>
                                    <v-card-text>
                                    <v-simple-table striped hover style="width:100%;">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th style="border:1px solid #ccc;">{{lang.plate_number}}</th>
                                                    <td>{{ car.plate_number }}</td>
                                                    <th style="border:1px solid #ccc;">{{lang.car_model}}</th>
                                                    <td>{{ car.car_model }}</td>
                                                </tr>
                                                <tr>
                                                    <th style="border:1px solid #ccc;">{{lang.car_year}}</th>
                                                    <td>{{ car.car_year }}</td>
                                                    <th style="border:1px solid #ccc;">{{lang.car_color}}</th>
                                                    <td>{{ car.car_color }}</td>
                                                </tr>
                                            </thead>
                                        </template>
                                    </v-simple-table>
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row :style="`direction:`+lang.dir" class="m-2 mt-0">
                    <v-col cols="12" md="5" sm="12">
                        <CVLaborTable ref="labors" />
                        <CVSubletsTable ref="subletstable" />
                        <cardPayments ref="cardpayment" />
                    </v-col>
                    <v-col cols="12" md="7" sm="12">
                        <CVPartsTable ref="partsTbl" />
                        <CardExpenses ref="expenses" />
                        <CVAdditionalRow ref="additional" />
                        <v-row cols="12" md="7" sm="12">
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th class="text-center">{{lang.car_description}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in scrNotes" :key="index">
                                        <td class="text-center">{{item}}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row class="m-1 pa-0" :style="`margin-top:-25px;marign-right:5px;direction:`+lang.dir">
                <v-col cols="12">
                    <div class="accordion" role="invsummary">
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab" @click="invsummary = !!invsummary">
                        {{lang.invoice_samary}} </b-card-header>
                        <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <b-card-text>
                            <v-row>
                                <v-col cols="12" md="3" sm="6">
                                    <table class="table">
                                    <thead class="thead-dark">
                                        <tr>
                                        <th colspan="3">{{lang.spare_parts}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td>{{lang.total}}</td>
                                        <td></td>
                                        <td>{{ sums.parts }}</td>
                                        </tr>
                                        <tr style="display:none">
                                        <td>{{lang.discount}}</td>
                                        <td>0</td>
                                        <td>0</td>
                                        </tr>
                                        <tr>
                                        <td>{{lang.totals}}</td>
                                        <td></td>
                                        <td>{{ sums.parts }}</td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </v-col>
                                <v-col cols="12" md="3" offset-md="1" sm="6">
                                <table class="table">
                                    <thead class="thead-dark">
                                    <tr>
                                    <th colspan="3">{{lang.others}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr style="display:none">
                                    <td>العمالة</td>
                                    <td></td>
                                    <td>{{ sums.labor }}</td>
                                    </tr>
                                    <tr>
                                    <td>{{lang.work_price}}</td>
                                    <td></td>
                                    <td>{{ sums.service }}</td>
                                    </tr>
                                    <tr>
                                    <td>{{lang.vat}} 15%</td>
                                    <td></td>
                                    <td>{{ sums.vats }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                </v-col>
                                <v-col cols="12" md="3" sm="6">
                                <table class="table">
                                    <thead class="thead-dark">
                                    <tr>
                                    <th colspan="3">{{lang.total}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                    <td>{{lang.ftotal}}</td>
                                    <td></td>
                                    <td>{{ sums.total }}</td>
                                    </tr>
                                    <tr>
                                    <td>{{lang.paid}}</td>
                                    <td></td>
                                    <td>{{ sums.paid }}</td>
                                    </tr>
                                    <tr>
                                    <td>{{lang.remain}}</td>
                                    <td></td>
                                    <td>{{ sums.remain }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                </v-col>
                                <v-col cols="12" md="2" sm="6">
                                <!-- <div style="margin:2px;margin-top:20px" v-if="isCanceled === false"><b-button v-b-toggle.add_addpayment class="mybtn" style="background:green"><i class="fas fa-money-bill"></i>&nbsp;&nbsp; سداد دفعة </b-button></div> -->
                                <!-- <div style="margin:2px;margin-top:20px"><b-button @click="goWhats()" class="mybtn" style="background:green"><i class="fas fa-whatsapp"></i>&nbsp;&nbsp; Whatsapp </b-button></div> -->
                                
                                </v-col>
                            </v-row>
                            
                            </b-card-text>
                        </b-card-body>
                        </b-collapse>
                    </b-card>
                    </div>
                </v-col>
                </v-row>

            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
        <viewCardGallery ref="gallrey" />
        <AddCardPayment ref="paymentCard" :cAmount="sums.remain"  />
    </div>
</template>

<script>
import axios from 'axios'
import viewCardGallery from "@/components/viewCardGallery.vue"
import CVLaborTable from "@/components/CVLaborTable.vue"
import CVSubletsTable from "@/components/CVSubletsTable.vue"
import cardPayments from "@/components/cardPayments.vue"
import CVPartsTable from "@/components/CVPartsTable.vue"
import CardExpenses from "@/components/card_expenses.vue"
import CVAdditionalRow from "@/components/CVAdditionalRow.vue"
import AddCardPayment from '@/components/addCardPayment.vue'
import {SnotifyPosition} from 'vue-snotify';
export default {
    components:{
        viewCardGallery,CVLaborTable,CVSubletsTable,cardPayments,CVPartsTable
        ,CardExpenses, CVAdditionalRow,AddCardPayment
    },
    data() {
        return {
            cardid: 0,
            card: {
                clicked: '',
            },
            sums:{
                labor: 0,
                service: 0,
                parts: 0,
                paid: 0,
                vats: 0,
                total: 0,
                remain: 0,
            },
            scrNotes: [],
        }
    },
    computed:{
        carddate: function(){
            // // console.log(this.card.date_in);
            return this.card.date_in
        },
        cardtime: function(){
            return this.card.created_date
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        showaddinv: function(){
            if(this.card.invid != 0 || this.card.invid == ''){
                return false;
            }
            return true;
        },
        Customer: function(){
            return {
                name: this.card.customer,
                mobile: this.card.mobile,
            }
        },
        company: function(){
            return {
                name: this.card.company_name,
                vatid: this.card.company_vatid,
            }
        },
        car: function(){
            return {
                plate_number: this.card.plate_number,
                car_model: this.card.car_model,
                car_year: this.card.car_year,
                car_color: this.card.car_color,
            }
        },
    },
    created() {
        setInterval(() => {this.calckInv();},1000);
        
    },
    methods: {
        updateHome(){
            this.$parent.getCurrentCards();
        },
        printInvo(id){
            //window.open('../api/print.php?invid='+this.card.inv_id,'_blank');
            this.printPDF(this.card.inv_id);
        },
        closeCard(){
            this.closeMe();
        },
        closeMe(){
            const post = new FormData();
            post.append('type','saveCard');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[cardid]',this.cardid);
            post.append('data[close]',1);
            post.append('data[statusper]',this.card.statusper);
            post.append('data[clicked]',this.card.clicked);

            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                if (this.$snotify.notifications.length > 0) {
                    this.$snotify.notifications.forEach(notification => {
                        this.$snotify.remove(notification.id)
                    });
                }
                const res = response.data;
                if(res.error.number == 200){
                    let message = this.lang.card_closed;
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    document.getElementById('closecardview').click();
                    this.$parent.getCurrentCards();
                    if(this.showaddinv)
                        this.doCreate();
                }else{
                    let messg = this.lang.please_check_data;
                    if(res.error.number == 1500){
                        messg = this.lang.cant_close_card_when_it_not_fully_paid;
                    }
                    
                    this.$snotify.error(messg, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                }
            }).then(() => {
                this.getCard();
                this.updateChileds();
                this.$parent.getCurrentCards();
            })
        },
        doCreate(){
            //document.getElementById('printbtns').disabled = true;
            const formdata = new FormData();
            formdata.append('type','createInvoice');
            formdata.append('auth',this.$cookies.get(this.$COOKIEPhase));
            formdata.append('data[cardid]',this.cardid);
            axios.post(
                this.$SAMCOTEC.r_path, formdata
            ).then((response) => {
                const res = response.data;
                this.card.invid = res.results.data;
                this.printPDF(res.results.data);
                this.getCard();
                this.updateChileds();
            }) 
        },
        _printPDF ()
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print.php?cards='+this.$route.params.cardid;
        },
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print.php?invid='+id;
        },
        getPay(){
            this.$refs.paymentCard.cardid = this.cardid;
            this.$refs.paymentCard.received_total = this.sums.remain;
            this.$refs.paymentCard.getPyamentType();
        },
        calckInv(){
            const post = new FormData();
            post.append('type','getCalckInv');
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[cardid]',this.cardid);
            axios.post(
                this.$SAMCOTEC.r_path, post 
            ).then((response) => {
                const res = response.data;
                this.sums = res.results.data;
            })
        },
        printMe(){
            window.open('../api/print.php?cards='+this.cardid,'_blank');
        },
        updateChileds(){
            // setTimeout(() => {// console.log("i am is cancel", this.showaddinv);},2000)
            // const showaddinv = this.card.invid != 0 ? false : true;
            this.$refs.gallrey.card.cardid = this.cardid;
            this.$refs.gallrey.getImages();

            this.$refs.labors.cardid = this.cardid;
            this.$refs.labors.getLabor();
            this.$refs.labors.getLaborSelect();
            this.$refs.labors.showbuttoms = this.card.invid != 0 ? false : true;
            // this.$refs.labors.iscancel = 
            // this.$refs.subletstable.iscancel = this.showaddinv;
            this.$refs.subletstable.cardid = this.cardid;
            this.$refs.subletstable.getService();
            this.$refs.subletstable.getWorkerds();
            this.$refs.subletstable.showbuttoms = this.card.invid != 0 ? false : true;
            this.$refs.subletstable.getWorkerds();
            
            this.$refs.cardpayment.cardid = this.cardid;
            this.$refs.cardpayment.getPayments();

            this.$refs.partsTbl.cardid = this.cardid;
            this.$refs.partsTbl.getparts();
            this.$refs.partsTbl.showbuttoms = this.card.invid != 0 ? false : true;
            // this.$refs.partsTbl.iscancel = this.showaddinv;
            this.$refs.expenses.cardid = this.cardid;
            this.$refs.expenses.getexpense(1);
            this.$refs.additional.cardid = this.cardid;
            // // console.log("clickedss" , this.card.clicked);
            const clt = String(this.card.clicked).split(',');
            this.$refs.additional.clicked = clt;
            // this.$refs.additional.getCard();
            this.$refs.additional.doSelect();
            this.$refs.paymentCard.cardid = this.cardid;
            this.$refs.paymentCard.full_name = this.card.full_name;
            this.$refs.paymentCard.mobile = this.card.mobile;
        },
        getCard(){
            // this.cardid = this.cardid;
            let post = new FormData();
            post.append('type' , 'getCards');
            const auth = this.$cookies.get(this.$store.state.COOKIEPhase);
            post.append('auth',auth);
            post.append('data[cardid]',this.cardid)
            axios.post(
                this.$store.state.SAMCOTEC.r_path,
                post
            ).then((response) => {
                const res = response.data;
                // this.Customer.name = res.results.data[0].customer;
                // this.Customer.mobile = res.results.data[0].mobile;
                // this.card.cardid = res.results.data[0].id;
                // this.card.sdate = res.results.data[0].date_in;
                // this.card.delivery = res.results.data[0].date_out;
                // this.card.statusper = res.results.data[0].status;
                // this.card.status = res.results.data[0].status;
                // this.card.cancel_by = res.results.data[0].cancel_by;
                // this.card.cancel_reson = res.results.data[0].cancel_reson;
                // this.card.cancel_date = res.results.data[0].cancel_date;
                // this.card.inv_id = +parseFloat(res.results.data[0].inv_id);
                // this.card.inv_iscancel = res.results.data[0].inv_iscancel;
                // this.card.inv_cancel_by = res.results.data[0].inv_cancel_by;
                // this.card.inv_cancel_date = res.results.data[0].inv_cancel_date;
                // this.card.inv_cancel_resone = res.results.data[0].inv_cancel_resone;
                
                // const cl = res.results.data[0].clicked;
                // const clt = cl.split(',');
                // for(let i = 0;i<clt.length;i++){ 
                //     this.card.clicked.push(parseInt(clt[i])); 
                // }
                // this.card.clicked = res.results.data[0].clicked;
                // this.car.plate_number = res.results.data[0].plate_number;
                // this.invoicedata = res.results.data[0];
                // this.car.car_model = res.results.data[0].car_model;
                // this.car.car_madel_year = res.results.data[0].car_madel_year;
                // this.car.car_color = res.results.data[0].car_color;
                // this.theclicked = this.card.clicked;
                // // this.getClicked("testing ",this.card.clicked);

                // if(+res.results.data[0].status < 0 || +res.results.data[0].status == 100){
                // this.isCanceled = true;
                // }
                // // console.log(res.results.data[0].scrachnotes);
                this.scrNotes = res.results.data[0].scrachnotes.split(" -||- ");
                this.updateChileds();
            })
        },
    },
}
</script>
<style>
.font-size-14{
  font-size:14px;
}
.mybtn{
  width:100%;
  font-size:.8rem;
  border:0px solid #fff;
}
.mybtn2{
  width:100%;
  font-size:.8rem;
  border:0px solid #fff;
}
.mybtn4{
  width:100%;
  font-size:.8rem;
  background:rgb(15, 90, 230) !important;
  border:0px solid #fff;
}
.mybtn3{
  width:100%;
  font-size:.8rem;
  background:#198754 !important;
  border:0px solid #fff;
}
.mybtn1{
  width:100%;
  font-size:.8rem;
  background:#fe7b33 !important;
  border:0px solid #fff;
}
.v-application .elevation-1{
 box-shadow: none !important;
}
</style>