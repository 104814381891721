<template>
  <div id="Footer">
    <small> 2006-{{ new Date().getFullYear() }} All Rights Reserved to SAMCOTEC

      <img :src="`${publicPath}img/samcotec-logo.png`" style="height:20px;margin-top:5px;float:left">
      <div style="float:right;right:10px">{{$store.state.SystemName}} V {{$store.state.Version}}</div>
    </small>
    <!-- <img id="ecarlogo" :src="`${publicPath}img/small-logo-light.png`" style="width:60px;float:right;margin-right:10px;"> -->
  </div>
</template>
<script>
export default {
  data: () =>  ({
    publicPath: window.location.origin+process.env.BASE_URL
  }),
}
</script>
<style>
#Footer{
  position: fixed;
  bottom: 0;
  left:0;
  right:0;
  height: 35px;
  background: #e9e9e9;
  border-top:1px solid #ccc;
  padding:5px;
  text-align: center;
}
</style>