<template>
    <div class="s-loading">
        
    </div>
</template>
<script>
import axios from 'axios'
export default{
    created() {
        // this.getAllSMS();
        this.chgetCookies();
    },
    methods: {
        chgetCookies(){
            let x = this.$cookies.get(this.$COOKIEPhase);
            
            if(String(x) == "null"){
                this.connected = false;
                // console.log(this.$route.name);
                if(this.$route.name != 'Login')
                    this.$router.push({name: "Login"})
            }else{
                this.connected = true;
                // console.log(this.$route.name);
                if(this.$route.name == 'Login' || this.$route.name == 'Loading')
                    this.$router.push({path: '/home'});
            }
        },
        getAllSMS(){
            const post = new FormData();
            post.append("type","getSMSTemp");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[all]",'123')
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            )
            .then((res) => {
                // console.log(res.data);
                this.$store.state.smsTemp = res.data.results.data;
            })
        },
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
}
</script>
<style>
.s-loading{
    position:fixed;
    top:0;
    left:0;
    bottom: 0;
    right: 0;
    background: #00000055;
}
</style>